import Container from "@mui/material/Container";

import "../App.css";
import { Grid, Typography } from "@mui/material";

import UniHeader from "../compositions/UniHeader";

function DetailsPage() {
  return (
    <Container disableGutters maxWidth="md" className="container">
      <UniHeader blueLogo />
      <Container disableGutters maxWidth="md" className="testContainer">
        <Grid
          item
          container
          mt={2}
          padding={2}
          spacing={5}
          alignContent="center"
          justifyContent={"space-evenly"}
          direction={"column"}>
          <Grid item>
            <Typography color={"primary"} variant="h3">
              Å pante bør være enkelt og effektivt.
            </Typography>
          </Grid>

          <Grid item mb={5}>
            <Typography variant="body1" color="primary">
              Unibottle tilbyr en tjeneste for håndtering av pant og oversikt
              over panteforbruket til en gitt forbruker, enten privatperson
              eller bedrift. Dere avleverer deres panteposer i våre containere,
              og vi håndterer resten.
              <br></br>
              Vi tilbyr foreløpig to ulike løsninger:
            </Typography>

            <br></br>
            <Typography variant="body1" color="primary">
              Borettslag:
              <br></br>
              For dine beboere vil dette bety at de kan kvitte seg med pant på
              akkurat lik linje som å kvitte seg med vanlig restavfall. Vi vil
              utplassere en container hos dere hvor beboere kan kvitte seg
              enkelt med sine pante-poser ved å følge en enkel guide i vår
              web-applikasjon. Dine beboere vil få en konto hos oss, hvor de
              opparbeider seg en saldo basert på antall flasker de leverer i
              våre containere. De vil også ha en innsikt i sitt pante-forbruk og
              vaner ved å ha en full oversikt over alle tidligere pantinger i
              vår web-applikasjon.
            </Typography>

            <br></br>
            <Typography variant="body1" color="primary">
              Bedrift:
              <br></br>
              Vi har erfart av bedrifter ofte ikke har noen strukturert
              håndtering av pant, noen ganger ikke noe håndtering i det hele
              tatt. Med vår løsning vil det bli utplassert en container på deres
              eiendom hvor din bedrift kan kvitte med seg panten i. Det vil være
              en kontaktperson innad i hver avdeling (hvis bedriften har flere
              avdelinger) som vi vil ha en dialog med for å forstå deres behov.
              Dere vil få en oversikt over pante-forbruk og vaner på lik linje
              med privatpersoner, enten på avdeling eller bedriftsnivå.
              <br></br>
              Vi støtter også at deres ansatte kan bruke vår tjeneste som
              privatpersoner. Sleng med dere panteposen i bilen på vei til jobb
              og over i vår container på deres arbeidsplass, så tar vi hånd om
              resten.
            </Typography>

            <br></br>
            <Typography variant="body1" color="primary">
              Priser:
              <br></br>
              Denne løsningen er helt gratis og tiltaksfri for dere som
              styreledere og bedriftseiere. Vi i Unibottle tar fullt ansvar for
              tømming og vedlikehold av våre containere. Det vi trenger er
              dataen og innsikten deres beboere vil gi oss slik at vi kan
              utvikle morgendagens pantesystem.
              <br></br>
              For alle pantinger pantinger som blir gjort som en privatperson og
              bedrift tar unibottle en prosentvis sum av det totale pantebeløpet
              som forekommer av hver panting.
              <br></br>
              For alle pantinger som blir gjort gjennom en unibox tar unibottle
              30% av totalbeløpet, hvorav 70% går tilbake til bruker.
              <br></br>
              For alle pantinger pantinger som blir gjort gjennom bestilling av
              henting av en bedrift, tar unibottle 50% av totalbeløpet som
              forekommer av hver bestilling.
              <br></br>
              Gebyrene som tillegges for bruk av tjenesten går utelukkende til
              videreutviklig og innovasjon av vår plattform, med et felles mål
              om å forbedre panteprosessen for brukere.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default DetailsPage;
