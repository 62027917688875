import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "@fontsource/inter";
import "./assets/fonts/neurialgrotesk.woff";

import App from "./App";

import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DetailsPage from "./pages/DetailsPage";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./theme/mainTheme";
import CompanyPage from "./pages/CompanyPage";
import InterestPage from "./pages/InterestPage";

import "./i18n";
import InformationPage from "./pages/InformationPage";
import InterestPageNew from "./pages/InterestPageNew";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        {/* <Route path="*" element={<ErrorPage />} /> */}
        <Route path="/">
          <Route index element={<App />} />
          <Route path="/home" element={<App />} />
          <Route path="/details" element={<DetailsPage />} />
          <Route path="/interest-form" element={<InterestPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/information-page" element={<InformationPage />} />
          <Route
            path="/information-page-registration"
            element={<InformationPage navigateRegistration />}
          />
          <Route path="/info-interest-form" element={<InterestPageNew />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);
