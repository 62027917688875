import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputText from "../../components/InputText/InputText";
import { InformationWrapper, InformationPagesData } from "./InformationWrapper";

export function InformationPageOne({
  image,
  body,
  title,
  qoute,
}: InformationPagesData) {
  return (
    <InformationWrapper>
      <Grid
        item
        container
        direction={"column"}
        alignItems="center"
        justifyContent={"space-between"}
        xs={12}>
        <Grid item>{image}</Grid>
        <Grid
          mt={4}
          item
          container
          spacing={2}
          direction={"column"}
          alignItems="center"
          justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={700} color="primary">
              {title}
            </Typography>
          </Grid>
          <Grid item maxWidth={300} xs={12}>
            <Typography textAlign={"center"} color="primary" fontSize={15}>
              {body}
            </Typography>
          </Grid>
        </Grid>
        <Grid item mt={10}>
          <Typography textAlign={"center"} color="primary" fontSize={13}>
            {qoute}
          </Typography>
        </Grid>
      </Grid>
    </InformationWrapper>
  );
}
