import * as React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

import { AppDescriptionImage } from "../assets/images/AppDescriptionImage";
import { AppDescriptionPhone } from "../assets/images/AppDescriptionPhone";
import { useTranslation } from "react-i18next";
import { AppDescriptionImageEn } from "../assets/images/AppDescriptionImageEn";

function AppDescription() {
  const language = sessionStorage.getItem("LANGUAGE_NORWEGIAN");

  const { t } = useTranslation(["home"]);
  const matches = useMediaQuery("(max-width:900px)");
  const dialogScaling = useMediaQuery("(max-width:600px)");
  const smallPhone = useMediaQuery("(max-width: 380px)");
  return (
    <Grid
      item
      container
      direction={"column"}
      xs={12}
      mb={10}
      alignItems={dialogScaling ? "center" : "flex-start"}
      justifyContent="center">
      <Grid item>
        <Typography
          variant={dialogScaling ? "h5" : "h4"}
          color={"primary"}
          fontSize={smallPhone ? 20 : 25}
          textAlign={smallPhone ? "start" : "start"}>
          {t("appDescriptionTitle")}
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body1" maxWidth={800} color="primary">
          {t("appDescriptionBody.0")}{" "}
          <Box component="span" fontWeight="bold">
            {t("appDescriptionBody.1")}{" "}
          </Box>
          {t("appDescriptionBody.2")}{" "}
          <Box component="span" fontWeight="bold">
            {t("appDescriptionBody.3")}{" "}
          </Box>
          {t("appDescriptionBody.4")}{" "}
          <Box component="span" fontWeight="bold">
            {t("appDescriptionBody.5")}{" "}
          </Box>
          {t("appDescriptionBody.6")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        mt={dialogScaling ? 6 : 4}
        container
        direction={"column"}>
        {dialogScaling ? (
          <Grid item container direction={"column"}>
            <Grid item container alignItems={"center"} justifyContent="center">
              <Grid item>
                <AppDescriptionPhone />
              </Grid>
            </Grid>
            <Grid item mt={2}>
              <Typography
                variant={"body1"}
                color={"primary"}
                fontSize={smallPhone ? 15 : 20}
                fontWeight="700"
                textAlign={smallPhone ? "start" : "start"}>
                {t("appDescriptionPhone.title")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={"body1"}
                color={"primary"}
                component="div"
                textAlign={smallPhone ? "start" : "start"}>
                <span>
                  <ul>
                    <li>{t("appDescriptionPhone.list.0")}</li>
                    <li>{t("appDescriptionPhone.list.1")}</li>
                    <li>{t("appDescriptionPhone.list.2")}</li>
                    <li>{t("appDescriptionPhone.list.3")}</li>
                    <li>{t("appDescriptionPhone.list.4")}</li>
                  </ul>
                </span>
              </Typography>
            </Grid>
          </Grid>
        ) : language === "true" ? (
          <AppDescriptionImage />
        ) : (
          <AppDescriptionImageEn />
        )}
      </Grid>
    </Grid>
  );
}

export default AppDescription;
