import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ReactElement, ReactNode } from "react";

type FormWrapperProps = {
  children: ReactNode;
};

export interface InformationPagesData {
  image?: ReactElement;
  title?: string;
  body?: string;
  qoute?: string;
  finalNavigation?: () => void;
}

export function InformationWrapper({ children }: FormWrapperProps) {
  return (
    <>
      <Grid
        xs={12}
        item
        container
        justifyContent={"space-between"}
        direction={"column"}
        alignItems="flex-start">
        {children}
      </Grid>
    </>
  );
}
