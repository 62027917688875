import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";
import { NavigationHandler } from "../../reactRouter/NavigationHandler";
import { CloseCrossIcon } from "../../assets/icons/CloseCrossIcon";
import { FaceWithBottles } from "../../assets/images/FaceWithBottles";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

interface IUnidialog {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  open: boolean;
  fullWidth?: boolean;

  onClose: () => void;
}

function Unidialog({
  children,
  onClick,
  open,
  fullWidth,

  onClose,
}: IUnidialog) {
  const { handleLoggIn } = NavigationHandler();
  const { t } = useTranslation(["home"]);
  return (
    <Dialog maxWidth={"xs"} open={open} onClose={onClose}>
      <Grid
        container
        maxWidth={"sm"}
        alignItems="center"
        justifyContent={"space-between"}
        direction="column">
        <Grid
          item
          container
          justifyContent={"flex-end"}
          style={{ backgroundColor: "#FEFAF1" }}>
          <Grid item mr={3} mt={3}>
            <IconButton onClick={onClose}>
              <CloseCrossIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-evenly"}
          alignItems={"center"}
          direction={"column"}
          className={"drawerContent"}>
          <Grid item overflow={"hidden"}>
            <FaceWithBottles />
          </Grid>
          <Grid item pl={4} pr={4} mb={2}>
            <Typography variant="body1" textAlign={"center"}>
              {t("interestPage.dialog.title")}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              style={{
                borderRadius: "24px",
                maxWidth: 400,
                width: "60vw",
                maxHeight: 40,
              }}
              btnTxt={t("interestPage.dialog.button")}
              onClick={onClick}
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              style={{ borderRadius: "24px" }}
              btnTxt={t("interestPage.dialog.logOnButton")}
              onClick={() => handleLoggIn()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default Unidialog;
