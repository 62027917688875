import * as React from "react";

import Drawer from "@mui/material/Drawer";

import "./UniDrawer.css";

import { ReactNode } from "react";
import { Grid, IconButton, Typography } from "@mui/material";

import Button from "../Button/Button";
import { FaceWithBottles } from "../../assets/images/FaceWithBottles";
import { CloseCrossIcon } from "../../assets/icons/CloseCrossIcon";
import { NavigationHandler } from "../../reactRouter/NavigationHandler";
import { useTranslation } from "react-i18next";

interface IUniDrawer {
  children?: ReactNode;
  open: boolean;
  height?: number;
  onClose: () => void;
}

export default function UniDrawer({ children, open, onClose }: IUniDrawer) {
  const { handleInterest, handleLoggIn } = NavigationHandler();

  const { t } = useTranslation(["home"]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <Drawer
          PaperProps={{ className: "drawerContaier" }}
          anchor={"bottom"}
          open={open}>
          <Grid
            item
            container
            justifyContent={"flex-end"}
            style={{ backgroundColor: "#FEFAF1" }}>
            <Grid item mr={3} mt={3}>
              <IconButton onClick={onClose}>
                <CloseCrossIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent={"space-evenly"}
            alignItems={"center"}
            direction={"column"}
            className={"drawerContent"}>
            <Grid item overflow={"hidden"}>
              <FaceWithBottles />
            </Grid>

            <Grid item pl={2} pr={2} mb={2}>
              <Typography variant="body1" textAlign={"center"}>
                {t("interestPage.dialog.title")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ borderRadius: "24px", maxWidth: 400 }}
                size="medium"
                btnTxt={t("interestPage.dialog.button")}
                onClick={() => handleInterest()}
              />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                style={{ borderRadius: "24px" }}
                size="medium"
                btnTxt={t("interestPage.dialog.logOnButton")}
                onClick={() => handleLoggIn()}
              />
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
