import "./App.css";
import Container from "@mui/material/Container";

import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import { Box, Divider, Typography, useMediaQuery } from "@mui/material";

import Button from "./components/Button/Button";

import { useEffect, useState } from "react";
import UniHeader from "./compositions/UniHeader";

import UniDrawer from "./components/Drawer/UniDrawer";

import Footer from "./compositions/Footer";

import Unidialog from "./components/UniDialog/UniDialog";
import InterestDialog from "./components/UniDialog/InterestDialog";
import CompaniesOverview from "./compositions/CompaniesOverview";
import { SimpleStepsPC } from "./assets/images/SimpleStepsPC";
import { SimpleStepsPhone } from "./assets/images/SimpleStepsPhone";
import AppDescription from "./compositions/AppDescription";
import FloatingQuestionAction from "./compositions/FloatingQuestionAction";
import UnibottleHistory from "./compositions/UnibottleHistory";
import ThreeSimpleSteps from "./compositions/TheeSimpleSteps";
import { NavigationHandler } from "./reactRouter/NavigationHandler";
import { useSessionStorage } from "./Storage/useSessionStorage";
import { SimpleStepsPcEn } from "./assets/images/SimpleStepsPcEn";
import { SimpleStepsPhoneEn } from "./assets/images/SimpleStepsPhoneEn";

function App() {
  // denne er en environment variabel som blir lagt til under publisering.
  // for å bruke denne lokalt, opprett en .env fil og legg den inn der
  //const apiURL = process.env.REACT_APP_API_URL;
  //const compPeriod = period;

  const language = sessionStorage.getItem("LANGUAGE_NORWEGIAN");

  const { t, i18n } = useTranslation(["home"]);
  //ratingValue states
  const [openRating, setOpenRating] = useState<boolean>(false);
  const matches = useMediaQuery("(max-width:900px)");
  const dialogScaling = useMediaQuery("(max-width:600px)");
  const smallPhone = useMediaQuery("(max-width: 380px)");

  const [openInterest, setopenInterest] = useState<boolean>(false);

  //This handles the alternative rating flow
  function CloseDrawer() {
    setOpenRating(false);
    setopenInterest(false);
  }
  function openInterestForm() {
    setopenInterest(true);
  }

  const { handleRegistration, handleInterest } = NavigationHandler();

  return (
    <Container
      maxWidth="lg"
      style={{ padding: "0" }}
      className="containerPicture">
      <Container maxWidth="lg" className="testContainer">
        <UniHeader />
        <Grid
          container
          item
          height={"90%"}
          xs={12}
          alignItems="center"
          direction={"column"}
          justifyContent={"center"}>
          <Grid
            item
            textAlign={"center"}
            justifyContent="center"
            container
            direction={"row"}
            width={dialogScaling ? "90vw" : smallPhone ? "100vw" : "60vw"}>
            <Grid item xs={12}>
              <Typography
                lineHeight={1.3}
                fontWeight={"fontWeightLight"}
                variant={matches ? "h6" : "h5"}
                style={smallPhone ? { fontSize: "17px" } : {}}
                color={"primary"}>
                {t("LandingScreenTitle.0")}
                <Box component="span" fontWeight="bold">
                  {t("LandingScreenTitle.1")}
                </Box>
                {t("LandingScreenTitle.2")}
                <Box component="span" fontWeight="bold">
                  {t("LandingScreenTitle.3")}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                lineHeight={1.3}
                style={smallPhone ? { fontSize: "17px" } : {}}
                fontWeight={"fontWeightLight"}
                variant={matches ? "h6" : "h5"}
                color={"primary"}>
                {t("LandingScreenTitle.4")}
                <Box component="span" fontWeight="bold">
                  {t("LandingScreenTitle.5")}
                </Box>
                {t("LandingScreenTitle.6")}
                <Box component="span" fontWeight="bold">
                  {t("LandingScreenTitle.7")}
                </Box>
                {t("LandingScreenTitle.8")}
              </Typography>
            </Grid>
            <Grid item mt={15}>
              <Button
                style={{
                  width: "170px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  maxHeight: 50,
                  maxWidth: 170,
                }}
                btnTxt={t("landingScreenButton")}
                onClick={() => setOpenRating(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" disableGutters>
        <Grid
          item
          container
          justifyContent="center"
          alignItems={"center"}
          mt={dialogScaling ? 10 : 20}
          spacing={2}>
          <Grid item xs={12}>
            <Typography
              lineHeight={1.3}
              fontSize={25}
              fontWeight={400}
              textAlign="center"
              color={"primary"}
              mr={5}
              ml={5}>
              {t("companiesOverviewTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CompaniesOverview />
          </Grid>
          <Grid item width={1}>
            <Divider
              flexItem
              style={{ borderBottomWidth: "medium", borderColor: "#E4EDF0" }}
              variant="fullWidth"
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" disableGutters>
        <Grid
          item
          container
          justifyContent="center"
          alignItems={"center"}
          mt={dialogScaling ? 20 : 30}>
          <Grid item xs={12}>
            <Typography
              lineHeight={1.3}
              fontSize={25}
              fontWeight={400}
              textAlign="center"
              color={"primary"}
              mr={5}
              ml={5}>
              {t("simpleStepsTitle")}
            </Typography>
          </Grid>
          <Grid item xs={11} mt={5}>
            {language === "true" ? (
              !dialogScaling ? (
                <SimpleStepsPC />
              ) : (
                <SimpleStepsPhone />
              )
            ) : !dialogScaling ? (
              <SimpleStepsPcEn />
            ) : (
              <SimpleStepsPhoneEn />
            )}
          </Grid>
        </Grid>
      </Container>{" "}
      <Container maxWidth="lg">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={"center"}
          mt={dialogScaling ? 20 : 30}>
          <AppDescription />
          <FloatingQuestionAction
            buttonText={t("appDescriptionInterest.button")}
            containerText={t("appDescriptionInterest.body")}
            onClick={handleInterest}
          />
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={"center"}
          mt={dialogScaling ? 20 : 30}>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="center"
            mb={8}>
            <Grid item mb={2}>
              <Typography
                color={"primary"}
                variant="h6"
                fontSize={smallPhone ? 20 : 25}
                textAlign={smallPhone ? "start" : "center"}>
                {t("historyTitle")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={"primary"}
                variant="h5"
                fontStyle={"italic"}
                fontSize={smallPhone ? 15 : 20}
                textAlign={smallPhone ? "center" : "center"}>
                "{t("historyQuestion")}"
              </Typography>
            </Grid>
          </Grid>
          <UnibottleHistory />
          <FloatingQuestionAction
            buttonText={t("historyActionInterest.button")}
            containerText={t("historyActionInterest.body")}
            onClick={handleInterest}
          />
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems={"center"}
          mt={dialogScaling ? 20 : 25}>
          <Grid item mb={8}>
            <Typography variant="h5" color={"primary"} textAlign="center">
              {t("threeSimpleSteps.title")}
            </Typography>
          </Grid>
          <ThreeSimpleSteps />
          <Grid
            item
            mt={4}
            container
            mb={10}
            xs={12}
            spacing={2}
            direction={"column"}
            alignItems="center"
            justifyContent={"center"}>
            <Grid item>
              <Button
                onClick={() => openInterestForm()}
                style={{
                  borderRadius: "33px",
                  width: "175px",
                  minHeight: "35px",
                }}
                btnTxt={t("threeSimpleSteps.button")}
              />
            </Grid>
            <Grid item container alignItems={"center"} justifyContent="center">
              <Grid item>
                <Typography variant="subtitle1" color={"primary"}>
                  {t("threeSimpleSteps.registerCompanyLine.0")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  type="button"
                  btnTxt={t("threeSimpleSteps.registerCompanyLine.1")}
                  onClick={handleRegistration}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Footer />
      </Container>
      {dialogScaling ? (
        <UniDrawer open={openRating} onClose={CloseDrawer} />
      ) : (
        <Unidialog
          open={openRating}
          onClose={CloseDrawer}
          onClick={openInterestForm}
        />
      )}
      <InterestDialog open={openInterest} onClose={CloseDrawer} />
    </Container>
  );
}

export default App;
