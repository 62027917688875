import "./ProgressionStepper.css";

import { Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface IProgressionStepper {
  value: number;

  padding?: number;
}

function DottedStepper({ value, padding }: IProgressionStepper) {
  return (
    <Grid
      paddingLeft={padding ? padding : 0}
      paddingRight={padding ? padding : 0}
      item
      container
      xs={12}
      direction={"row"}
      justifyContent="center"
      alignItems={"center"}>
      <Grid
        item
        alignItems={"center"}
        justifyContent="center"
        className={`dotted-circle ${value === 0 && `dotted-circle-disabled`}`}
      />
      <Grid
        ml={1}
        mr={0.5}
        item
        alignItems={"center"}
        justifyContent="center"
        className={`dotted-circle ${value === 1 && `dotted-circle-disabled`}`}
      />
      <Grid
        ml={0.5}
        mr={1}
        item
        alignItems={"center"}
        justifyContent="center"
        className={`dotted-circle ${value === 2 && `dotted-circle-disabled`}`}
      />
      <Grid
        item
        alignItems={"center"}
        justifyContent="center"
        className={`dotted-circle ${value === 3 && `dotted-circle-disabled`}`}
      />
    </Grid>
  );
}

export default DottedStepper;
