const ProfileIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_2030_10570" fill="white">
        <path d="M1.21898 14.1556C0.405467 12.7086 -0.0146813 11.0737 0.000391632 9.41374C0.0154646 7.7538 0.465233 6.12678 1.30489 4.69478C2.14454 3.26279 3.34477 2.0758 4.78599 1.25208C6.22721 0.428368 7.85912 -0.0033233 9.51913 1.92588e-05C11.1791 0.00336181 12.8093 0.441621 14.2472 1.27113C15.6851 2.10065 16.8805 3.29246 17.7144 4.72782C18.5483 6.16319 18.9915 7.79201 18.9999 9.452C19.0083 11.112 18.5815 12.7452 17.7622 14.1889L16.8591 13.6764C17.5889 12.3905 17.969 10.9358 17.9615 9.45724C17.954 7.9787 17.5593 6.52792 16.8165 5.24944C16.0738 3.97096 15.009 2.90942 13.7283 2.17058C12.4476 1.43173 10.9956 1.04138 9.51704 1.0384C8.03848 1.03542 6.58494 1.41993 5.30125 2.15361C4.01756 2.88729 2.94852 3.94454 2.20064 5.22001C1.45277 6.49548 1.05216 7.94466 1.03873 9.42317C1.02531 10.9017 1.39953 12.3579 2.12412 13.6467L1.21898 14.1556Z" />
      </mask>
      <path
        d="M1.21898 14.1556C0.405467 12.7086 -0.0146813 11.0737 0.000391632 9.41374C0.0154646 7.7538 0.465233 6.12678 1.30489 4.69478C2.14454 3.26279 3.34477 2.0758 4.78599 1.25208C6.22721 0.428368 7.85912 -0.0033233 9.51913 1.92588e-05C11.1791 0.00336181 12.8093 0.441621 14.2472 1.27113C15.6851 2.10065 16.8805 3.29246 17.7144 4.72782C18.5483 6.16319 18.9915 7.79201 18.9999 9.452C19.0083 11.112 18.5815 12.7452 17.7622 14.1889L16.8591 13.6764C17.5889 12.3905 17.969 10.9358 17.9615 9.45724C17.954 7.9787 17.5593 6.52792 16.8165 5.24944C16.0738 3.97096 15.009 2.90942 13.7283 2.17058C12.4476 1.43173 10.9956 1.04138 9.51704 1.0384C8.03848 1.03542 6.58494 1.41993 5.30125 2.15361C4.01756 2.88729 2.94852 3.94454 2.20064 5.22001C1.45277 6.49548 1.05216 7.94466 1.03873 9.42317C1.02531 10.9017 1.39953 12.3579 2.12412 13.6467L1.21898 14.1556Z"
        stroke="#013A65"
        strokeWidth="2"
        mask="url(#path-1-inside-1_2030_10570)"
      />
      <path
        d="M7.5 9.5C8.60457 10.6046 10.3954 10.6046 11.5 9.5C12.6046 8.39543 12.6046 6.60457 11.5 5.5C10.3954 4.39543 8.60457 4.39543 7.5 5.5C6.39543 6.60457 6.39543 8.39543 7.5 9.5Z"
        stroke="#013A65"
        strokeLinecap="round"
      />
      <path
        d="M15.5 16C15.5 16.5158 15.026 17.1415 13.8867 17.6673C12.7898 18.1736 11.2394 18.5 9.5 18.5C7.76059 18.5 6.21018 18.1736 5.11333 17.6673C3.97402 17.1415 3.5 16.5158 3.5 16C3.5 15.4842 3.97402 14.8585 5.11333 14.3327C6.21018 13.8264 7.76059 13.5 9.5 13.5C11.2394 13.5 12.7898 13.8264 13.8867 14.3327C15.026 14.8585 15.5 15.4842 15.5 16Z"
        stroke="#013A65"
      />
    </svg>
  );
};

export default ProfileIcon;
