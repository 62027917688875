export const RightArrow = () => {
  return (
    <svg
      width="100%"
      height="20"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 7C0.947715 7 0.5 7.44772 0.5 8C0.5 8.55228 0.947715 9 1.5 9V7ZM22.719 8.70711C23.1095 8.31658 23.1095 7.68342 22.719 7.29289L16.355 0.928932C15.9645 0.538408 15.3314 0.538408 14.9408 0.928932C14.5503 1.31946 14.5503 1.95262 14.9408 2.34315L20.5977 8L14.9408 13.6569C14.5503 14.0474 14.5503 14.6805 14.9408 15.0711C15.3314 15.4616 15.9645 15.4616 16.355 15.0711L22.719 8.70711ZM1.5 9H22.0119V7H1.5V9Z"
        fill="#013A65"
      />
    </svg>
  );
};
