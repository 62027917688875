export const LeftArrow = () => {
  return (
    <svg
      width="100%"
      height="20"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 7C22.0523 7 22.5 7.44772 22.5 8C22.5 8.55228 22.0523 9 21.5 9V7ZM0.292709 8.70711C-0.0978146 8.31658 -0.0978146 7.68342 0.292709 7.29289L6.65667 0.928932C7.0472 0.538408 7.68036 0.538408 8.07088 0.928932C8.46141 1.31946 8.46141 1.95262 8.07088 2.34315L2.41403 8L8.07088 13.6569C8.46141 14.0474 8.46141 14.6805 8.07088 15.0711C7.68036 15.4616 7.0472 15.4616 6.65667 15.0711L0.292709 8.70711ZM21.5 9H0.999817V7H21.5V9Z"
        fill="#013A65"
      />
    </svg>
  );
};
