export const LinkedIn = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 20.5V16.0355C14.5 15.3725 14.7634 14.7366 15.2322 14.2678V14.2678C16.2085 13.2915 17.7915 13.2915 18.7678 14.2678V14.2678C19.2366 14.7366 19.5 15.3725 19.5 16.0355V20.5"
        stroke="#013A65"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 12V20.5"
        stroke="#013A65"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="14" cy="14" r="13" stroke="#013A65" strokeWidth="2" />
      <path
        d="M9.17574 9.17574C9.60216 9.60216 10.3121 9.53186 10.6466 9.03009C10.8606 8.70909 10.8606 8.29091 10.6466 7.96991C10.3121 7.46814 9.60216 7.39784 9.17574 7.82426C8.80254 8.19746 8.80254 8.80254 9.17574 9.17574Z"
        fill="#013A65"
        stroke="#013A65"
      />
    </svg>
  );
};
