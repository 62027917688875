export const Facebook = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="13" stroke="#013A65" strokeWidth="2" />
      <path
        d="M10.5 17H20"
        stroke="#013A65"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.5 10C20.0523 10 20.5 9.55228 20.5 9C20.5 8.44772 20.0523 8 19.5 8V10ZM13 26.5V27.5H15V26.5H13ZM19.5 8H18V10H19.5V8ZM13 13V26.5H15V13H13ZM18 8C15.2386 8 13 10.2386 13 13H15C15 11.3431 16.3431 10 18 10V8Z"
        fill="#013A65"
      />
    </svg>
  );
};
