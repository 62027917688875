import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "../App.css";
import { Button, Divider, useMediaQuery } from "@mui/material";

import { Link } from "react-router-dom";
import { Instagram } from "../assets/icons/Instagram";
import { Facebook } from "../assets/icons/Facebook";
import { LinkedIn } from "../assets/icons/LinkedIn";
import { NavigationHandler } from "../reactRouter/NavigationHandler";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation(["home"]);

  const { handleFacebook, handleInstagram, handleLinkedIn } =
    NavigationHandler();

  const smallPhone = useMediaQuery("(max-width: 380px)");

  return (
    <Grid container direction={"column"} alignItems="center" spacing={2}>
      <Grid
        item
        container
        direction={"row"}
        alignItems="baseline"
        justifyContent="space-between">
        <Grid item container direction={"column"}>
          <Grid item>
            <Typography color={"primary"} fontSize={17} fontWeight={700}>
              unibottle AS
            </Typography>
          </Grid>
          <Grid item>
            <Typography color={"#4D7593"} fontSize={15} fontWeight={200}>
              +47 902 99 536
            </Typography>
          </Grid>
          <Grid item container justifyContent={"space-between"}>
            <Grid item xs={5}>
              <Link
                style={{ color: "#4D7593" }}
                to="mailto:kontakt@unibottle.no">
                <Typography fontSize={15} fontWeight={200} color={"#4D7593"}>
                  kontakt@unibottle.no
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              container
              xs={7}
              alignItems="center"
              justifyContent={"flex-end"}>
              <Grid item width={smallPhone ? 42 : "auto"}>
                <Button style={{ padding: "0px" }} onClick={handleInstagram}>
                  <Instagram />
                </Button>
              </Grid>
              <Grid item width={smallPhone ? 42 : "auto"}>
                <Button style={{ padding: "0px" }} onClick={handleFacebook}>
                  <Facebook />
                </Button>
              </Grid>
              <Grid item width={smallPhone ? 42 : "auto"}>
                <Button style={{ padding: "0px" }} onClick={handleLinkedIn}>
                  <LinkedIn />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item width={1}>
        <Divider />
      </Grid>

      <Grid item container direction={"column"} xs={6}>
        <Grid item>
          <Typography color={"#4D7593"} fontSize={17} fontWeight={200}>
            {t("footer.secondTitle")}
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Link to={"/details"}>
              <Typography color={"primary"} fontSize={13} fontWeight={200}>
                {t("footer.pricesButton")}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to={"/details"}>
              <Typography color={"primary"} fontSize={13} fontWeight={200}>
                {t("footer.termsButton")}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to={"/privacy-policy"}>
              <Typography color={"primary"} fontSize={13} fontWeight={200}>
                {t("footer.privacyButton")}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item width={1}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        mb={10}
        alignItems="center"
        justifyContent={"center"}>
        <Grid item>
          <Typography color={"primary"} fontSize={13} fontWeight={200}>
            Org.nr. 826 570 652
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={"primary"} fontSize={13} fontWeight={200}>
            |
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={"primary"} fontSize={13} fontWeight={200}>
            Abbedikollen 26, 0280 Oslo
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
