import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

function ThreeSimpleSteps() {
  const { t } = useTranslation(["home"]);
  const stepsData = [
    {
      step: t("threeSimpleSteps.steps.0.step"),
      title: t("threeSimpleSteps.steps.0.title"),
      mainText: t("threeSimpleSteps.steps.0.body"),
    },
    {
      step: t("threeSimpleSteps.steps.1.step"),
      title: t("threeSimpleSteps.steps.1.title"),
      mainText: t("threeSimpleSteps.steps.1.body"),
    },
    {
      step: t("threeSimpleSteps.steps.2.step"),
      title: t("threeSimpleSteps.steps.2.title"),
      mainText: t("threeSimpleSteps.steps.2.body"),
    },
  ];
  const dialogScaling = useMediaQuery("(max-width:800px)");
  return (
    <Grid
      item
      container
      xs={dialogScaling ? 6 : 12}
      minWidth={260}
      alignItems={"flex-start"}
      direction={dialogScaling ? "column" : "row"}
      justifyContent="space-evenly">
      {stepsData.map((info, steps) => (
        <Grid
          key={steps}
          item
          xs={3.5}
          minHeight={200}
          container
          direction={"column"}
          p={2}
          style={
            dialogScaling
              ? {
                  border: "1px solid #013A65",
                  borderRadius: "33px",
                  marginBottom: 10,
                }
              : {
                  border: "1px solid #013A65",
                  borderRadius: "33px",
                }
          }>
          <Grid item>
            <Typography fontSize={14} color="primary" variant="body1">
              {info.step}
            </Typography>
          </Grid>
          <Grid item pr={1}>
            <Typography
              variant="h6"
              fontSize={18}
              fontWeight={600}
              color="primary">
              {info.title}
            </Typography>
          </Grid>
          <Grid item overflow="scale" justifyContent="center">
            <Typography variant="body1" fontSize={14} color="primary">
              {info.mainText}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default ThreeSimpleSteps;
