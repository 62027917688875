import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function NavigationHandler() {
  const navigate = useNavigate();

  const handleHome = useCallback(
    () =>
      navigate("/home", {
        replace: true,
      }),
    [navigate]
  );

  /*const handleBedrift = useCallback(
    () => navigate("/company", { replace: false }),
    [navigate]
  );*/

  const handlePolicy = useCallback(
    () => navigate("/privacy-policy", { replace: false }),
    [navigate]
  );

  const handleInterest = useCallback(
    () => navigate("/interest-form", { replace: false }),
    [navigate]
  );

  const handleInterestInfoSteps = useCallback(
    () => navigate("/info-interest-form", { replace: false }),
    [navigate]
  );

  const handleRegistration = () => {
    window.open("https://pant.unibottle.no/signin/enterprise");
  };

  const handleLoggIn = () => {
    window.open("https://pant.unibottle.no");
  };

  const handleInstagram = () => {
    window.open("https://www.instagram.com/unibottle_/");
  };

  const handleFacebook = () => {
    window.open("https://www.facebook.com/profile.php?id=100076155635854");
  };

  const handleLinkedIn = () => {
    window.open("https://no.linkedin.com/company/unibottle-as");
  };

  return {
    handleLoggIn,
    handlePolicy,
    // handleBedrift,
    handleHome,
    handleInterestInfoSteps,
    handleRegistration,
    handleInterest,
    handleFacebook,
    handleInstagram,
    handleLinkedIn,
  };
}
