import { Button as MuiButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import { LeftArrow } from "../assets/icons/LeftArrow";
import { RightArrow } from "../assets/icons/RightArrow";
import { PageFourStep } from "../assets/images/infoSteps/PageFourStep";
import { PageOneStep } from "../assets/images/infoSteps/PageOneStep";
import { PageThreeStep } from "../assets/images/infoSteps/PageThreeStep";
import { PageTwoStep } from "../assets/images/infoSteps/PageTwoStep";
import Button from "../components/Button/Button";
import LanguageShifter from "../components/LanguageShifter/LanguageShifter";
import DottedStepper from "../components/ProgressionStepper/DottedStepper";
import { InformationPageFour } from "../compositions/informationSteps/InformationPageFour";
import { InformationPageOne } from "../compositions/informationSteps/InformationPageOne";
import { InformationPageThree } from "../compositions/informationSteps/InformationPageThree";
import { InformationPageTwo } from "../compositions/informationSteps/InformationPageTwo";
import { useMultistepForm } from "../compositions/informationSteps/useMultistepForm";
import { NavigationHandler } from "../reactRouter/NavigationHandler";
import theme from "../theme/mainTheme";
import DefaultWrapper from "../wrapper/DefaultWrapper";

interface IInformationPage {
  navigateRegistration?: boolean;
}

function InformationPage({ navigateRegistration }: IInformationPage) {
  const { t } = useTranslation(["steps"]);
  const matches = useMediaQuery("(min-width:900px)");
  const handlePhone = useMediaQuery("(min-width:500px)");
  const smallPhone = useMediaQuery("(max-width: 380px)");

  const { handleHome, handleLoggIn, handleInterestInfoSteps } =
    NavigationHandler();

  const handleStepChange = (step: number) => {
    goToStep(step);
  };

  const {
    step,
    goToStep,
    isLastStep,
    nextStep,
    backStep,
    secondToLastStep,
    currentStepIndex,
    steps,
    isFirstStep,
    isMiddleStep,
  } = useMultistepForm([
    <InformationPageOne
      image={<PageOneStep />}
      title={t("pageOne.title")}
      body={t("pageOne.body")}
      qoute={t("pageOne.qoute")}
    />,
    <InformationPageTwo
      image={<PageTwoStep />}
      title={t("pageTwo.title")}
      body={t("pageTwo.body")}
      qoute={t("pageTwo.qoute")}
    />,
    <InformationPageThree
      image={<PageThreeStep />}
      title={t("pageThree.title")}
      body={t("pageThree.body")}
      qoute={t("pageThree.qoute")}
    />,
    <InformationPageFour
      image={<PageFourStep />}
      title={t("pageFour.title")}
      body={t("pageFour.body")}
      qoute={t("pageFour.qoute")}
      finalNavigation={
        navigateRegistration ? handleLoggIn : handleInterestInfoSteps
      }
    />,
  ]);

  useEffect(() => {
    if (matches) {
      handleHome();
    }
  }, []);

  return (
    <DefaultWrapper noHeader>
      <Grid
        item
        container
        xs={12}
        direction="column"
        alignItems={"center"}
        justifyContent="center">
        <Grid
          item
          container
          mt={6}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}>
          <Grid item xs={7} ml={0.5}>
            <LanguageShifter flexStart />
          </Grid>
          <Grid item>
            <Button
              style={{ textDecoration: "none", fontWeight: 500 }}
              variant="text"
              btnTxt={t("closeButton")}
              onClick={handleHome}
            />
          </Grid>
        </Grid>
        <Grid item mt={handlePhone ? 20 : 5}>
          {step}
        </Grid>
      </Grid>
      <Grid
        style={{ position: "absolute", bottom: "3rem" }}
        item
        container
        pl={4}
        pr={4}
        direction={"row"}
        alignItems="center"
        justifyContent="space-between">
        <Grid item xs={2}>
          {isFirstStep ? (
            <></>
          ) : (
            <MuiButton
              size="small"
              onClick={() => {
                backStep();
              }}
              disabled={isFirstStep}>
              <LeftArrow />
            </MuiButton>
          )}
        </Grid>
        <Grid item>
          <DottedStepper value={currentStepIndex} />
        </Grid>
        <Grid item xs={2}>
          {isLastStep ? (
            <></>
          ) : (
            <MuiButton
              size="small"
              onClick={() => {
                nextStep();
              }}>
              <RightArrow />
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </DefaultWrapper>
  );
}

export default InformationPage;
