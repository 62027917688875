import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEnglish from "./Translation/English/english.json";
import translationNorwegian from "./Translation/Norwegian/norwegian.json";

import enPrivacy from "./Translation/English/en-privacy.json";
import noPrivacy from "./Translation/Norwegian/no-privacy.json";

import enInformation from "./Translation/English/en-informationSteps.json";
import noInformation from "./Translation/Norwegian/no-informationSteps.json";

//---Using different namespaces
const resources = {
  en: {
    home: translationEnglish,
    privacy: enPrivacy,
    steps: enInformation,
  },

  no: {
    home: translationNorwegian,
    privacy: noPrivacy,
    steps: noInformation,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "no", //default language
});

export default i18next;
