import { Container, Grid, Typography } from "@mui/material";

import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button/Button";
import InputText from "../components/InputText/InputText";
import StatusHandler from "../components/StatusHandler/StatusHandler";
import { NavigationHandler } from "../reactRouter/NavigationHandler";
import { SendinblueEmail } from "../types/SendinblueEmail";
import DefaultWrapper from "../wrapper/DefaultWrapper";

function InterestPageNew() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { t } = useTranslation(["steps"]);

  const { handleHome } = NavigationHandler();

  const [email, setEmail] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  const sendinblueApiKey =
    "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";
  const sendinblueApiUrl = "https://api.sendinblue.com/v3/smtp/email";

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function sendEmail(email: SendinblueEmail) {
    try {
      // eslint-disable-next-line
      const response = await fetch(sendinblueApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": sendinblueApiKey,
        },
        body: JSON.stringify(email),
      });
      // eslint-disable-next-line
      const data = await response.json();
      setIsLoading(false);
      setIsSuccess(true);
      sleep(3000).then(() => {
        setIsSuccess(false);
        handleHome();
      });
    } catch (error) {
      setIsError(true);
      sleep(3000).then(() => {
        setIsError(false);
        handleHome();
      });
    }
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    const emailToUnibottle: SendinblueEmail = {
      sender: { name: "Unibottle", email: "noreply@unibottle.no" },
      to: [{ email: "kontakt@unibottle.no" }],
      subject: "Interesse for unibox tjeneste - bedrift",
      htmlContent:
        "<p>En bedrift har vist interesse etter informasjon fra QR-kode</p> " +
        "Epost: " +
        email +
        "</br>" +
        "Navn: " +
        name +
        "</br>" +
        "Telefonnummer: " +
        number +
        "</br>" +
        "Navn på bedrift: " +
        companyName,
    };

    const emailToCustomer: SendinblueEmail = {
      sender: { name: "Unibottle", email: "noreply@unibottle.no" },
      to: [{ email: email! }],
      subject: "Bekreftelse på forespørsel - unibottle",
      htmlContent:
        "<p>Hei, Dette er en bekreftelse på at vi har mottatt din forespørsel og vi vil ta kontakt med deg fortløpende</p></br> <p>Hvis det skulle være noe mer du lurte på kan du kontakte oss på: kontakt@unibottle.no</p> </br> </br><p>Mvh Unibottle</p> </br></br> <p>English:</p> </br> <p>Hi, This is an automatic confirmation regarding your interest registrated at unibottle.no. We will get in touch with you as soon as possible.</p></br> <p>In the meantime. If there is anything else we can help you with, feel free to contact us at: kontakt@unibottle.no</p> </br> </br><p>Best regards Unibottle</p>",
    };

    sendEmail(emailToUnibottle);
    sendEmail(emailToCustomer);
    setIsLoading(true);
  }

  return (
    <StatusHandler
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}>
      <Container maxWidth="md" className="container">
        <Container disableGutters maxWidth="sm" className="testContainer">
          <Grid
            item
            container
            xs={12}
            direction="column"
            alignItems={"center"}
            justifyContent="center">
            <Grid
              item
              container
              mt={6}
              xs={12}
              justifyContent="flex-end"
              alignItems={"center"}>
              <Grid item>
                <Button
                  style={{ textDecoration: "none", fontWeight: 500 }}
                  variant="text"
                  btnTxt={t("closeButton")}
                  onClick={handleHome}
                />
              </Grid>
            </Grid>
            <form onSubmit={onSubmit}>
              <Grid
                item
                xs={12}
                container
                mt={3}
                justifyContent={"space-evenly"}
                alignItems="center"
                direction={"column"}>
                <Grid
                  item
                  container
                  spacing={5}
                  justifyContent={"space-evenly"}
                  direction={"column"}>
                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        color="primary">
                        {t("interestForm.title")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={13} color={"primary"}>
                        {t("interestForm.underTitle")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction={"column"} spacing={3}>
                    <Grid item>
                      <InputText
                        name="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}
                        underline
                        required
                        label={t("interestForm.formData.company")}
                        type="text"
                      />
                    </Grid>
                    <Grid item>
                      <InputText
                        underline
                        required
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        label={t("interestForm.formData.email")}
                        type="email"
                      />
                    </Grid>
                    <Grid item>
                      <InputText
                        required
                        name="mobile"
                        onChange={(e) => setNumber(e.target.value)}
                        maxLength={8}
                        underline
                        label={t("interestForm.formData.phone")}
                        type="number"></InputText>
                    </Grid>
                    <Grid item>
                      <InputText
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        underline
                        label={t("interestForm.formData.name")}
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={8}>
                  <Button
                    size="large"
                    type="submit"
                    onClick={() => {}}
                    style={{
                      borderRadius: "40px",
                      maxHeight: "38px",
                      maxWidth: "315px",
                    }}
                    btnTxt={t("interestForm.sendButton")}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Container>
      </Container>
    </StatusHandler>
  );
}

export default InterestPageNew;
