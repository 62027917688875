import { FidlLogo } from "./FidlLogo";
import { ForskningsparkenLogo } from "./ForskningsparkenLogo";
import { InstaBoxLogo } from "./InstaboxLogo";
import { RightSizeLogo } from "./RightSizeLogo";
import { SeenLogo } from "./SeenLogo";
import { StartblokkaLogo } from "./StartblokkaLogo";
import { TelenorLogo } from "./TelenorLogo";

interface companyInformation {
  name: string;
  description: string;
  logo: JSX.Element;
}

const CompanyData: companyInformation[] = [
  {
    name: "Forskningsparken",
    description: "Forskningsparken",
    logo: <ForskningsparkenLogo />,
  },
  {
    name: "Instabox",
    description: "Forskningsparken",
    logo: <InstaBoxLogo />,
  },
  {
    name: "Telenor",
    description: "Forskningsparken",
    logo: <TelenorLogo />,
  },
  {
    name: "Fidl",
    description: "Fidl",
    logo: <FidlLogo />,
  },
  {
    name: "Seen",
    description: "Seen",
    logo: <SeenLogo />,
  },
  {
    name: "Startblokka",
    description: "Startblokka",
    logo: <StartblokkaLogo />,
  },
  {
    name: "Right Size",
    description: "Right Size",
    logo: <RightSizeLogo />,
  },
];

export default CompanyData;
