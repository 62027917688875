import * as React from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import "../App.css";
import Carusell from "./Carusell";
import InfoSteps from "./InfoSteps";
import { InfoContent } from "../types/InfoContent";
import CompanyData from "../assets/CompanyData/CompanyData";
import Button from "../components/Button/Button";
import { Padding } from "@mui/icons-material";

interface IFloatingQuestion {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonText: string;
  containerText: string;
}

function FloatingQuestionAction({
  buttonText,
  onClick,
  containerText,
}: IFloatingQuestion) {
  const smallPhone = useMediaQuery("(max-width: 600px)");
  return (
    <Grid
      item
      container
      direction={smallPhone ? "column" : "row"}
      xs={12}
      maxWidth={smallPhone ? 400 : 800}
      padding="20px"
      style={{
        borderRadius: "31px",
        backgroundColor: "#E4EDF0",
      }}
      alignItems="center"
      justifyContent="space-between">
      <Grid item xs={8}>
        <Typography variant="body2" color={"primary"}>
          {containerText}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        container
        mt={smallPhone ? 3 : 0}
        alignItems="center"
        justifyContent={smallPhone ? "center" : "flex-end"}>
        <Button
          btnTxt={buttonText}
          size="small"
          style={{ marginTop: "0", minHeight: "40px", borderRadius: "31px" }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
}

export default FloatingQuestionAction;
