import { Divider } from "@mui/material";
import React from "react";

import "./InputText.css";

interface IInputText {
  underline?: boolean;
  label?: string;
  size?: "small" | "medium" | "large";
  name?: string;
  ariaLabel?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  type: "text" | "number" | "email";
  maxLength?: number;
  defaultValue?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
function InputText({
  label,
  ariaLabel,
  placeholder,
  required,
  value,
  onChange,
  onClick,
  type,
  maxLength,
  underline,
  defaultValue,
}: IInputText) {
  const onlyNumbersAndLengthLimitation = (e: any) => {
    if (maxLength) {
      if (e.target.value.length >= maxLength) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
    }
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const onlyLettersAndLengthLimitation = (e: any) => {
    if (maxLength) {
      if (e.target.value.length >= maxLength) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
    }
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9\sæøå\-@.]+/, "");
  };

  return (
    <div className="input-text">
      <label className="smallLabel" color={"#4C7390"}>
        {required ? label + "*" : label}
      </label>

      <input
        type={type}
        required={required}
        aria-label={ariaLabel}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onInput={
          type === "number"
            ? (e) => {
                onlyNumbersAndLengthLimitation(e);
              }
            : (e) => onlyLettersAndLengthLimitation(e)
        }
        maxLength={maxLength}
      />
      {underline ? <Divider style={{ color: "primary" }} /> : <></>}
    </div>
  );
}

export default InputText;
