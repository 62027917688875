import { ReactNode } from "react";

import LoadingOverlay from "react-loading-overlay-ts";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface IStatusHandler {
  children: ReactNode;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  successText?: string;
  errorText?: string;
}

function StatusHandler({
  children,
  isLoading,
  isSuccess,
  isError,
  successText,
  errorText
}: IStatusHandler) {
  return (
    <LoadingOverlay
      active={isLoading || isError || isSuccess}
      spinner={
        isLoading ||
        (isError && (
          <div style={{ alignItems: "center" }}>
            <ErrorOutlineIcon fontSize="large" color="error" />
          </div>
        )) ||
        (isSuccess && (
          <div style={{ alignItems: "center" }}>
            <CheckCircleOutlineIcon fontSize="large" color="success" />
          </div>
        ))
      }
      text={
        (isLoading && "Laster..") ||
        (isError && (errorText ?? "Feil ved opplastning")) ||
        (isSuccess && (successText ?? "Fullført!"))
      }>
      {children}
    </LoadingOverlay>
  );
}

export default StatusHandler;
