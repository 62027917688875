export const FidlLogo = () => {
  return (
    <svg
      width="100%"
      height="68"
      viewBox="0 0 137 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.7502 14.622V17.2819H77.4682V9.58501L77.4682 9.57451C77.4674 9.40541 77.3833 1 69.0365 1C65.1778 1 63.1155 2.7964 62.0134 4.74497C60.3578 7.67243 60.7502 11.2588 60.7502 14.622Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
      <path
        d="M102.473 25.5703H60.0234V33.1408C60.0234 41.4674 59.4017 50.3301 63.731 57.4428C66.6782 62.2847 71.9555 66.7897 81.3935 66.8567C90.7569 66.9231 95.9644 62.4999 98.8591 57.675C103.212 50.4199 102.473 41.4011 102.473 32.9405V25.5703Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
      <path
        d="M38.0703 66.8567V25.5703H49.8456V66.8567H38.0703Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
      <path
        d="M1 67.002V25.5703H29.348V34.7289H12.4846V42.143H24.9868V50.8655H12.4846V67.002H1Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
      <path
        d="M111.629 66.7113V25.5703H123.259V57.9888H136.197V66.7113H111.629Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
      <path
        d="M97.6743 1H91.5686V6.23348H86.4805V12.3392H91.5686V17.4273H97.6743V12.3392H103.053V6.23348H97.6743V1Z"
        fill="#EA33D3"
        stroke="#EA33D3"
      />
    </svg>
  );
};
