export const CloseCrossIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 16L16 1M1 1L16 16" stroke="#013A65" strokeLinecap="round" />
    </svg>
  );
};
