export const BlueLogoSmall = () => {
  return (
    <svg
      width="92"
      height="18"
      viewBox="0 0 92 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1994_12345)">
        <path
          d="M2.51981 7.47168V13.3179C2.51981 13.9248 2.54555 14.6278 2.98303 15.1618C3.31758 15.5745 3.90946 15.8648 4.62895 15.8648C5.34844 15.8648 5.94033 15.5735 6.27487 15.1618C6.71235 14.6278 6.73809 13.9248 6.73809 13.3179V7.47168H9.25897V13.3908C9.25897 14.7006 9.10457 15.792 8.102 16.7387C7.12517 17.6601 5.78806 18 4.57855 18C3.47305 18 2.13487 17.6844 1.15804 16.7387C0.154406 15.793 0 14.7006 0 13.3908V7.47168H2.51981Z"
          fill="#013A65"
        />
        <path
          d="M12.4232 7.4721H14.9441V8.41782C16.0239 7.2779 17.258 7.20508 17.8757 7.20508C19.8819 7.20508 20.73 8.1508 21.0646 8.58775C21.4763 9.14506 21.7337 9.84904 21.7337 11.4259V17.6848H19.2128V12.0085C19.2128 9.4131 18.1845 9.34028 17.2323 9.34028C16.1011 9.34028 14.943 9.48593 14.943 12.7853V17.6859H12.4221V7.4721H12.4232Z"
          fill="#013A65"
        />
        <path
          d="M26.1825 1.72266C27.1078 1.72266 27.8027 2.37808 27.8027 3.25098C27.8027 4.12387 27.1078 4.7793 26.1825 4.7793C25.2571 4.7793 24.5623 4.12488 24.5623 3.25098C24.5623 2.37707 25.2571 1.72266 26.1825 1.72266Z"
          fill="#013A65"
        />
        <path
          d="M27.4424 7.47168H24.9226V17.6844H27.4424V7.47168Z"
          fill="#013A65"
        />
        <path
          d="M36.3668 7.20465C35.569 7.20465 34.1805 7.39885 33.1522 8.66015V0H30.6313V17.6854H33.1522V16.6183C34.2577 17.8311 35.6205 18.001 36.3925 18.001C39.7359 18.001 41.6391 15.3813 41.6391 12.5674C41.6391 9.24376 39.2212 7.20668 36.3668 7.20668V7.20465ZM36.058 15.8648C34.4635 15.8648 32.9978 14.7734 32.9978 12.6382C32.9978 10.503 34.4635 9.33884 36.058 9.33884C37.8841 9.33884 39.0668 10.7943 39.0668 12.614C39.0668 14.4336 37.8841 15.8648 36.058 15.8648Z"
          fill="#013A65"
        />
        <path
          d="M60.4937 9.65541V17.6854H57.9729V9.65541H56.9188V7.47166H57.9729V3.73633H60.4937V7.47166H62.4227V9.65541H60.4937Z"
          fill="#013A65"
        />
        <path
          d="M67.2318 9.65541V17.6854H64.711V9.65541H63.6569V7.47166H64.711V3.73633H67.2318V7.47166H69.1608V9.65541H67.2318Z"
          fill="#013A65"
        />
        <path d="M73.378 0H70.8582V17.6854H73.378V0Z" fill="#013A65" />
        <path
          d="M86.3406 12.8581C86.3406 11.79 86.2119 9.89857 84.8234 8.54017C84.18 7.91003 83.0745 7.20605 81.3514 7.20605C79.8084 7.20605 78.6257 7.74011 77.725 8.58873C76.5938 9.68009 76.0019 11.2084 76.0019 12.7853C76.0019 14.3622 76.568 15.7206 77.5963 16.6905C78.6246 17.6605 79.8084 18.0004 81.2484 18.0004C82.4054 18.0004 83.5624 17.7576 84.4373 17.2246C85.1322 16.8119 85.8002 16.1332 86.2634 15.3564L84.1543 14.2408C83.9227 14.6292 83.5892 15.0419 83.1517 15.3564C82.637 15.7206 82.1234 15.8662 81.4286 15.8662C80.811 15.8662 80.0915 15.7691 79.4996 15.2108C78.9592 14.701 78.6257 13.8767 78.5742 13.1484H86.3406V12.8571V12.8581ZM78.7544 11.2084C78.883 10.6986 79.1661 10.2131 79.5264 9.89857C79.8609 9.58299 80.426 9.24415 81.301 9.24415C82.1234 9.24415 82.6638 9.53545 82.9984 9.82574C83.3844 10.1656 83.6664 10.6511 83.7704 11.2084H78.7554H78.7544Z"
          fill="#013A65"
        />
        <path
          d="M90.457 15.0654C91.3052 15.0654 92 15.7198 92 16.5209C92 17.322 91.3052 17.9764 90.457 17.9764C89.6089 17.9764 88.914 17.322 88.914 16.5209C88.914 15.7198 89.6089 15.0654 90.457 15.0654Z"
          fill="#013A65"
        />
        <path
          d="M49.4848 7.20508C46.2187 7.20508 43.6978 9.43637 43.6978 12.5911C43.6978 15.7459 46.2177 18.0004 49.4848 18.0004C49.487 18.0004 49.4891 18.0004 49.4913 18.0004C49.3551 16.7644 49.2661 15.4414 49.2318 14.0598C49.2168 13.4761 48.7182 13.0048 48.0984 12.9906C46.6273 12.9573 45.2172 12.8733 43.9026 12.7438C45.2183 12.6144 46.6273 12.5304 48.0984 12.4971C48.7171 12.4829 49.2168 12.0126 49.2318 11.4279C49.2661 10.0402 49.3562 8.71013 49.4934 7.47008C49.6307 8.71115 49.7197 10.0402 49.755 11.4279C49.7701 12.0116 50.2687 12.4829 50.8884 12.4971C52.3596 12.5304 53.7696 12.6144 55.0842 12.7438C53.7685 12.8733 52.3596 12.9573 50.8884 12.9906C50.2697 13.0048 49.7701 13.4751 49.755 14.0598C49.7207 15.4414 49.6317 16.7644 49.4956 18.0004C52.7563 17.9954 55.2708 15.7165 55.2708 12.5911C55.2708 9.4657 52.7509 7.20508 49.4848 7.20508Z"
          fill="#013A65"
        />
      </g>
      <defs>
        <clipPath id="clip0_1994_12345">
          <rect width="92" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
