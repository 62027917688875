import { Container, Grid, Typography } from "@mui/material";

import UniHeader from "../compositions/UniHeader";

import "../App.css";
import { useTranslation } from "react-i18next";

function PrivacyPolicyPage() {
  const { t } = useTranslation(["privacy"]);

  return (
    <Container maxWidth="md" className="container">
      <UniHeader blueLogo />
      <Grid
        item
        xs={12}
        mt={4}
        container
        spacing={2}
        direction={"column"}
        alignItems="flex-start"
        justifyContent={"center"}>
        <Grid item>
          <Typography variant="h4" color="primary">
            {t("privacyTitle")}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h5" color="primary">
            {t("underTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary">
            {t("privacyBody.0")}
            <br></br>
            {t("privacyBody.1")}
            <br></br>
            {t("privacyBody.2")}
            <br></br>
            {t("privacyBody.3")}
          </Typography>
        </Grid>

        <Grid item mt={1} container direction={"column"} spacing={1}>
          <Grid item>
            <Typography variant="h4" color="primary">
              {t("dataGatherigTitle")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringBody.0")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringBody.1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringBody.2")}
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h5" color="primary">
              {t("dataGatheringCollection.title")}
            </Typography>
          </Grid>
          <Grid item>
            <ul className="PrivacyListStyling">
              <li>{t("dataGatheringCollection.list.0")}</li>
              <li>{t("dataGatheringCollection.list.1")}</li>
              <li>{t("dataGatheringCollection.list.2")}</li>
              <li>{t("dataGatheringCollection.list.3")}</li>
            </ul>
          </Grid>

          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringCollection.body")}
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h5" color="primary">
              {t("dataGatheringUsage.title")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringUsage.underTitle")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringUsage.underTitle1")}
            </Typography>
          </Grid>
          <Grid item>
            <ul className="PrivacyListStyling">
              <li>{t("dataGatheringUsage.list1.0")}</li>
              <li>{t("dataGatheringUsage.list1.1")}</li>
              <li>{t("dataGatheringUsage.list1.2")}</li>
            </ul>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataGatheringUsage.body1")}
            </Typography>
          </Grid>
          <Grid item>
            <ul className="PrivacyListStyling">
              <li>{t("dataGatheringUsage.list2.0")}</li>
              <li>{t("dataGatheringUsage.list2.1")}</li>
              <li>{t("dataGatheringUsage.list2.2")}</li>
              <li>{t("dataGatheringUsage.list2.3")}</li>
            </ul>
          </Grid>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="body2" color={"primary"}>
                {t("dataGatheringUsage.directMarketingTitle")}
              </Typography>
            </Grid>
            <Grid item>
              <ul className="PrivacyListStyling">
                <li>{t("dataGatheringUsage.directMarketingBody")}</li>
              </ul>
            </Grid>
          </Grid>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="body2" color={"primary"}>
                {t("dataGatheringUsage.safetyTitle")}
              </Typography>
            </Grid>
            <Grid item>
              <ul className="PrivacyListStyling">
                <li>{t("dataGatheringUsage.safetyBody")}</li>
              </ul>
            </Grid>
          </Grid>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="body2" color={"primary"}>
                {t("dataGatheringUsage.lawsTitle")}
              </Typography>
            </Grid>
            <Grid item>
              <ul className="PrivacyListStyling">
                <li>{t("dataGatheringUsage.lawsBody")}</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"column"} spacing={1}>
          <Grid item>
            <Typography variant="h4" color="primary">
              {t("dataSavingAndProtection.title")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={"primary"}>
              {t("dataSavingAndProtection.list.0")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="primary">
              {t("dataSavingAndProtection.list.1")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="primary">
              {t("dataSavingAndProtection.list.2")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h4" color="primary">
            {t("cookiesAndTracing.title")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color={"primary"}>
            {t("cookiesAndTracing.body")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" color="primary">
            {t("userPriveliges.title")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            {t("userPriveliges.underTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <ul className="PrivacyListStyling">
            <li>{t("userPriveliges.list.0")}</li>
            <li>{t("userPriveliges.list.1")}</li>
            <li>{t("userPriveliges.list.2")}</li>
            <li>{t("userPriveliges.list.3")}</li>
            <li>{t("userPriveliges.list.4")}</li>
          </ul>
        </Grid>
        <Grid item>
          <Typography variant="h4" color="primary">
            {t("questionsAndContact.title")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color={"primary"}>
            {t("questionsAndContact.body")}
          </Typography>
        </Grid>
        <Grid item mb={15}>
          <a href={"mailto:kontakt@unibottle.no"} style={{ color: "#013A65" }}>
            <Typography variant="body1" color={"primary"}>
              kontakt@unibottle.no
            </Typography>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PrivacyPolicyPage;
