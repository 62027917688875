import { Grid } from "@mui/material";
import React from "react";
import "./Button.css";

interface IButton {
  style?: React.CSSProperties;
  btnTxt: string;
  size?: "small" | "medium" | "large";
  variant?: "secondary" | "outlined" | "outlinedLight" | "circle" | "text";
  icon?: JSX.Element;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

function Button({
  style,
  btnTxt,
  size,
  icon,
  variant,
  type,
  onClick,
  disabled,
}: IButton) {
  return (
    <Grid item>
      <button
        style={style}
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={`
        btn 
        ${variant && `btn-${variant}`}
        ${size && `btn-${size}`}
        `}>
        {icon && <span className="btn-icon">{icon}</span>}
        {btnTxt}
      </button>
    </Grid>
  );
}

export default Button;
