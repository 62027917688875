export const Instagram = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="6"
        stroke="#013A65"
        strokeWidth="2"
      />
      <path
        d="M19.4614 13.9998C19.4614 16.887 17.2206 19.1598 14.5383 19.1598C11.856 19.1598 9.61523 16.887 9.61523 13.9998C9.61523 11.1127 11.856 8.83984 14.5383 8.83984C17.2206 8.83984 19.4614 11.1127 19.4614 13.9998Z"
        stroke="#013A65"
        strokeWidth="2"
      />
      <ellipse
        cx="21.5386"
        cy="6.72047"
        rx="2.15385"
        ry="2.24"
        fill="#013A65"
      />
    </svg>
  );
};
