import "../App.css";
import Grid from "@mui/material/Grid";

import Button from "../components/Button/Button";

import { NavigationHandler } from "../reactRouter/NavigationHandler";

import { BlueLogoSmall } from "../assets/icons/BlueLogoSmall";
import { BlueLogoPcLarge } from "../assets/icons/BlueLogoPcLarge";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { BlueLogoPc } from "../assets/icons/BlueLogoPc";
import { WhiteLogoSmall } from "../assets/icons/WhiteLogoSmall";
import ProfileIcon from "../assets/icons/ProfileIcon";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import LanguageShifter from "../components/LanguageShifter/LanguageShifter";

interface IUniHeader {
  company?: boolean;
  blueLogo?: boolean;
}

function UniHeader({}: IUniHeader) {
  const { t } = useTranslation(["home"]);
  const matches = useMediaQuery("(min-width:550px)");
  const smallPhone = useMediaQuery("(max-width: 380px)");

  const { handleLoggIn } = NavigationHandler();

  return (
    <Grid container spacing={1} direction={"column"} justifyContent={"center"}>
      {matches ? (
        <Grid
          marginTop={8}
          marginBottom={1}
          xs={12}
          item
          direction={"row"}
          container
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Grid item marginLeft={"12px"} xs={3}>
            <Link to={"/"}>
              <BlueLogoSmall />
            </Link>
          </Grid>
          <Grid
            item
            container
            xs={8}
            direction="row"
            justifyContent="flex-end"
            alignItems={"center"}>
            <LanguageShifter />
            <Grid item>
              <Button
                icon={<ProfileIcon />}
                style={{
                  backgroundColor: "#E4EDF0",
                  color: "#013A65",
                  border: "1px solid #013A65",
                  fontWeight: 400,
                  fontSize: "14px",
                  maxHeight: "35px",
                  borderRadius: "10px",
                }}
                onClick={handleLoggIn}
                btnTxt={t("headerLoginButton")}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          marginTop={5}
          marginBottom={1}
          xs={12}
          item
          direction={"row"}
          container
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Grid item marginLeft={"12px"} xs={3}>
            <Link to={"/"}>
              <BlueLogoSmall />
            </Link>
          </Grid>
          <Grid
            item
            container
            xs={8}
            spacing={1}
            justifyContent="flex-end"
            alignItems={"center"}>
            <LanguageShifter />
            <Grid item>
              <Button
                icon={<ProfileIcon />}
                style={{
                  backgroundColor: "#E4EDF0",
                  color: "#013A65",
                  border: "1px solid #013A65",
                  fontWeight: 400,
                  maxHeight: "35px",
                  borderRadius: "10px",
                }}
                onClick={handleLoggIn}
                btnTxt={t("headerLoginButtonPhone")}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default UniHeader;
