import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { NavigationHandler } from "../../reactRouter/NavigationHandler";
import { InformationWrapper, InformationPagesData } from "./InformationWrapper";

export function InformationPageFour({
  image,
  body,
  title,
  qoute,
  finalNavigation,
}: InformationPagesData) {
  const scalesDown = useMediaQuery("(min-width:468px)");
  const { t } = useTranslation(["steps"]);

  return (
    <InformationWrapper>
      <Grid
        item
        container
        direction={"column"}
        alignItems="center"
        justifyContent={"space-between"}
        xs={12}>
        <Grid item>{image}</Grid>
        <Grid
          item
          container
          mt={4}
          spacing={2}
          direction={"column"}
          alignItems="center"
          justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Typography fontSize={20} fontWeight={700} color="primary">
              {title}
            </Typography>
          </Grid>
          <Grid item maxWidth={300} xs={12}>
            <Typography textAlign={"center"} color="primary" fontSize={15}>
              {body}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          mt={scalesDown ? 6 : 2}
          direction={"column"}
          alignItems="center"
          spacing={2}
          justifyContent={"center"}>
          <Grid item>
            <Button
              size="large"
              onClick={finalNavigation}
              style={{
                borderRadius: "40px",
                maxHeight: "38px",
                maxWidth: "315px",
              }}
              btnTxt={t("continueButton")}
            />
          </Grid>
          <Grid item>
            <Typography textAlign={"center"} color="primary" fontSize={13}>
              {qoute}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </InformationWrapper>
  );
}
