export const PageThreeStep = () => {
  return (
    <svg
      width="100%"
      height="190"
      viewBox="0 0 192 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M129.084 190C107.449 190 62.5017 186.262 35.8841 162.394C8.65746 137.979 6.43875e-05 93.1812 6.43875e-05 71.1775C6.43875e-05 47.1055 30.1261 21.9447 54.9237 3.59721C74.9532 -11.2224 109.528 24.5379 129.083 24.5379C172.849 24.5379 186.429 39.2639 185.089 111.302C186.427 189.643 172.849 190 129.084 190Z"
        fill="#D7F0FF"
      />
      <path
        d="M7.3303 176.31L8.15548 177.89C8.15548 177.89 8.65868 178.931 8.65868 179.885C8.65868 180.079 8.582 180.294 8.48672 180.512C8.25528 181.041 8.15548 181.608 8.15548 182.186V182.269H74.936C74.936 182.269 74.9428 182.508 74.9428 181.077C74.9428 180.27 75.7148 178.111 75.6581 175.355C75.6144 173.228 74.6917 170.843 74.936 167.849C75.5601 160.2 76.9017 163.303 77.4325 160.2C78.0033 156.863 78.21 154.785 76.8084 151.596C74.7163 146.835 71.8154 144.425 65.5743 142.035C59.3331 139.645 60.5813 139.645 56.8366 138.211C54.7561 137.414 51.7122 137.355 49.4178 137.46C48.4381 137.505 47.8642 136.228 48.5862 135.564L49.123 135.071C49.2691 134.936 49.4502 134.849 49.6462 134.817C55.5897 133.862 63.5848 131.281 64.2777 131.056C64.3137 131.044 64.3433 131.032 64.3777 131.016L66.8642 129.874C67.2191 129.711 67.46 129.348 67.6437 129.003C68.0545 128.232 69.0086 127.204 69.5498 126.651C69.7914 126.405 70.1464 126.312 70.481 126.397L71.568 126.675C71.7304 126.716 71.9005 126.716 72.0628 126.675L72.763 126.496C73.2806 126.364 73.6029 125.848 73.4951 125.325L71.9999 118.072C71.8954 117.565 71.4174 117.221 70.9047 117.29C56.68 119.19 47.5375 127.156 45.4403 129.153C45.1545 129.425 44.737 129.526 44.3704 129.38C41.6903 128.311 40.7536 127.11 36.8649 126.74C32.6961 126.344 31.2478 127.696 27.5031 131.521C24.3829 134.707 21.696 139.553 20.8866 141.081C20.7391 141.359 20.8188 141.698 21.0688 141.889C21.4498 142.181 21.3993 142.772 20.9879 143.019C19.2997 144.033 18.2102 145.071 16.8931 145.862C13.965 147.619 12.093 148.926 10.8965 149.876C9.28697 151.153 8.6706 153.098 8.57461 155.15C8.47152 157.355 8.43233 160.253 8.7796 162.115C9.22081 164.48 8.4144 168.04 7.90384 169.924C7.67239 170.778 7.28342 171.579 7.02696 172.426C6.76194 173.301 6.72803 174.057 6.7623 174.6C6.8006 175.207 7.04875 175.77 7.3303 176.31Z"
        fill="#FEFAF1"
      />
      <path d="M18.5173 160.158V169.915" stroke="#013A65" strokeWidth="0.7" />
      <path
        d="M17.72 160.219C19.5232 160.219 21.2607 160.096 23.0587 160.096"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M26.9028 160.884C26.9028 161.608 26.9028 162.331 26.9028 163.054C26.9028 163.298 26.7251 164.46 26.9212 164.656C27.0929 164.828 28.2942 164.52 28.5188 164.514C29.5619 164.487 30.7245 164.371 31.6362 164.972C32.5275 165.56 32.6087 166.765 32.6524 167.742C32.703 168.871 32.5541 169.952 31.5446 170.63C30.6236 171.249 29.0541 171.244 27.9923 171.147C27.243 171.079 26.46 170.906 25.9141 170.36"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M26.2439 161.048C27.6597 161.048 29.0318 161.016 30.4371 160.888C30.605 160.873 30.9733 160.956 31.11 160.842C31.2561 160.721 31.9583 160.636 32.1766 160.636"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M18.6382 163.083C19.557 163.083 20.4814 163.052 21.3858 163.018C21.8521 163 22.3289 162.952 22.7816 162.952"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M37.3962 160.955C37.3962 164.498 37.4478 168.002 37.4478 171.521"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M36.573 161.109C37.3958 161.109 38.2185 161.109 39.0413 161.109C39.8265 161.109 40.6443 161.06 41.2605 161.624C41.7445 162.068 41.6918 162.759 41.6729 163.357C41.6367 164.496 40.6717 165.416 39.614 165.691C39.1064 165.822 37.5176 165.748 38.042 165.748C38.5679 165.748 39.0053 166.013 39.4852 166.209C39.9436 166.396 40.4146 166.561 40.8482 166.804C41.1037 166.948 41.1537 167.346 41.2004 167.603C41.2894 168.093 41.3131 168.65 41.2118 169.138C41.0286 170.022 40.6659 171.294 39.6541 171.509C38.7474 171.702 37.7709 171.521 36.8823 171.521"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path
        d="M45.7246 160.311C45.7246 161.955 46.1204 163.579 46.1371 165.229C46.139 165.42 46.2266 167.832 46.2746 167.796C46.7298 167.454 48.2511 167.735 48.7646 167.735C49.9052 167.735 51.0458 167.735 52.1864 167.735"
        stroke="#013A65"
        strokeWidth="0.7"
      />
      <path d="M52.1865 161.548V172.546" stroke="#013A65" strokeWidth="0.7" />
      <path
        d="M8.15548 181.316V181.316C8.15548 180.999 8.19282 180.682 8.32338 180.393C8.47535 180.057 8.65868 179.709 8.65868 179.408C8.65868 178.498 8.20013 177.507 8.1585 177.419C8.15628 177.414 8.15428 177.411 8.15187 177.406L7.63798 176.422C7.15166 175.491 6.70429 174.507 6.7594 173.458C6.77794 173.105 6.83021 172.705 6.93858 172.269C7.20575 171.196 7.70954 170.195 7.98941 169.126C8.49833 167.18 9.19684 163.875 8.7796 161.638C8.40559 159.633 8.47984 156.426 8.59942 154.177C8.69096 152.456 9.18464 150.813 10.511 149.712C11.6776 148.743 13.6292 147.344 16.8931 145.385C18.2642 144.562 19.3887 143.47 21.1987 142.418C21.5148 142.234 21.5536 141.784 21.2633 141.561L20.9663 141.334C20.7757 141.188 20.7144 140.931 20.8261 140.718C21.5536 139.335 24.3001 134.315 27.5031 131.044C31.2478 127.22 32.6961 125.867 36.8649 126.263C40.8965 126.647 41.7551 127.924 44.6733 129.02C44.858 129.09 45.0616 129.045 45.2023 128.906C46.7491 127.385 56.2364 118.623 71.3583 116.755C71.6151 116.723 71.8554 116.894 71.9076 117.148L73.5914 125.316C73.6453 125.577 73.4842 125.835 73.2254 125.901L71.9391 126.229C71.858 126.25 71.7729 126.25 71.6917 126.229L70.212 125.852C70.0447 125.809 69.8685 125.854 69.7463 125.976C69.2254 126.496 67.8288 127.942 67.5114 128.817C67.4448 129.001 67.3329 129.182 67.1554 129.263L64.3518 130.551C64.3346 130.559 64.3176 130.566 64.2996 130.572C63.7738 130.744 55.5256 133.418 49.4963 134.364C49.3982 134.38 49.3082 134.423 49.2351 134.491L47.4064 136.171C47.0454 136.503 47.3287 137.129 47.8174 137.087C50.0732 136.897 54.2179 136.731 56.8366 137.734C60.5813 139.168 59.3331 139.168 65.5743 141.558C71.8154 143.948 74.7163 146.358 76.8084 151.119C78.21 154.308 78.0033 156.386 77.4325 159.723C76.9017 162.826 75.5601 159.723 74.936 167.372C74.6917 170.366 75.6144 172.751 75.6581 174.878C75.7096 177.382 75.0772 179.862 74.961 180.295C74.9491 180.34 74.9428 180.385 74.9428 180.431V181.316"
        stroke="#013A65"
        strokeWidth="0.7"
        strokeLinecap="square"
      />
      <path
        d="M60.9039 170.612L53.8956 172.211C53.1129 172.39 52.4494 171.62 52.7418 170.872L55.2654 164.417C55.3252 164.265 55.4215 164.129 55.546 164.022L73.7553 148.415C75.2646 147.122 77.3356 146.318 79.1483 147.134C80.3336 147.668 81.0117 148.461 81.3561 149.65C81.9068 151.551 80.7964 153.476 79.3025 154.775L61.3374 170.391C61.2132 170.499 61.0643 170.575 60.9039 170.612Z"
        fill="#E4EDF0"
        stroke="#013A65"
        strokeWidth="0.7"
        strokeLinecap="square"
      />
      <path
        d="M71.7961 150.148L77.3181 156.5"
        stroke="#013A65"
        strokeWidth="0.7"
        strokeLinecap="square"
      />
      <path
        d="M188.469 153.349H183.374C183.174 153.349 183.012 153.187 183.012 152.988C183.012 152.788 183.174 152.626 183.374 152.626H188.469C188.565 152.626 188.657 152.664 188.725 152.732C188.953 152.96 188.791 153.349 188.469 153.349Z"
        fill="#013A65"
      />
      <path
        d="M155.051 156.242H150.89C150.52 156.242 150.188 156.017 150.05 155.674C149.813 155.08 150.25 154.434 150.89 154.434H155.051C155.346 154.434 155.623 154.578 155.792 154.82C156.212 155.419 155.783 156.242 155.051 156.242Z"
        fill="#013A65"
      />
      <path
        d="M117.906 151.965V151.843C117.906 151.574 118.119 151.353 118.387 151.343L122.089 151.201C122.373 151.19 122.609 151.417 122.609 151.7V152.108C122.609 152.392 122.373 152.618 122.089 152.607L118.387 152.465C118.119 152.455 117.906 152.234 117.906 151.965Z"
        fill="#013A65"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.384 51.5305L150.639 51.3496V150.636H147.564L147.384 51.5305Z"
        fill="#013A65"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M113.565 55.8726V147.02L115.374 147.12L115.555 82.6163V55.8726H113.565Z"
        fill="#013A65"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.385 150.636L115.374 147.381L115.555 55.6908H112.843V54.7865H113.747V52.978L149.374 51.5312V54.4248H147.566L147.385 150.636Z"
        fill="#013A65"
      />
      <path
        d="M115.555 55.6908L115.374 147.381L147.385 150.636L147.566 54.4248M115.555 55.6908L147.566 54.4248M115.555 55.6908H112.843V54.7865H113.747V52.978L149.374 51.5312V54.4248H147.566"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M191.873 53.569V146.477L150.821 149.009L150.64 51.3506L113.747 52.9783L124.417 48.6379L167.777 46.4194C168.579 46.3482 169.376 46.3071 170.171 46.2969C176.673 46.2138 183.08 48.1971 191.353 52.6937C191.674 52.8677 191.873 53.2046 191.873 53.569Z"
        fill="#013A65"
      />
      <path
        d="M150.64 51.3506L150.821 149.009L191.873 146.477V53.569C191.873 53.2046 191.674 52.8677 191.353 52.6937C183.08 48.1971 176.673 46.2138 170.171 46.2969M150.64 51.3506C158.223 48.1706 164.236 46.3728 170.171 46.2969M150.64 51.3506L113.747 52.9783L124.417 48.6379L170.171 46.2969"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.566 79.2006V65.0944V54.4243L124.779 55.3286L116.822 70.1582L116.099 76.8496V79.2006M147.566 79.2006H116.099M147.566 79.2006V82.6368H116.099L121.524 82.0942V80.2857L116.099 79.5623V79.2006"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M124.599 81.9127C125.142 80.4659 126.769 79.2 126.769 79.2H128.216C127.23 80.1573 125.818 81.843 125.865 81.9127C124.964 88.37 124.687 98.1941 124.618 101.341C124.606 101.899 125.056 102.349 125.614 102.349H131.955C132.492 102.349 132.934 101.923 132.954 101.386L133.642 82.8169C134.029 81.2494 134.426 80.4371 135.631 79.2H137.62C136.632 80.6125 135.45 81.0084 135.088 82.8169C134.776 84.3775 134.195 98.5959 134.041 102.465C134.02 103.009 133.569 103.426 133.025 103.417L124.338 103.27C123.784 103.26 123.343 102.805 123.359 102.251C123.598 93.9298 124.599 81.9127 124.599 81.9127Z"
        fill="#FEFAF1"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M119.355 151.903V147.743L120.982 147.924V151.903H119.355Z"
        fill="#013A65"
      />
      <path
        d="M151.002 154.616V148.829H152.63V154.616H151.002Z"
        fill="#013A65"
      />
      <path
        d="M185.724 152.627V146.839H187.352V152.627H185.724Z"
        fill="#013A65"
      />
      <path
        d="M140.026 105.057C139.008 106.421 139.918 109.222 140.694 109.222H144.311L144.311 109.584H145.939V110.126C145.939 110.126 145.939 110.488 146.3 110.488H147.566L147.385 104.52H141.561C140.99 104.52 140.368 104.6 140.026 105.057Z"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.385 109.22H146.842C146.842 109.22 146.842 108.677 146.842 108.135C146.842 107.593 145.938 107.593 145.938 108.135V109.221H144.672V108.859H141.959V108.497H144.491V107.774H140.693V105.242H144.491V105.061H147.385"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.307 60.9111L135.091 61.3474V60.2567L131.382 57.2025L130.292 55.2392L147.307 54.5847V60.9111Z"
        fill="#FEFAF1"
      />
      <path
        d="M147.564 65.0956L146.298 76.3083L127.49 76.5434L117.363 76.67L117.724 70.1594L125.501 55.3298L130.203 55.1489L131.288 57.1383L135.086 60.2127V61.4786L147.564 60.9361V65.0956Z"
        fill="#FEFAF1"
      />
      <path
        d="M147.564 65.0956L146.298 76.3083L127.49 76.5434M147.564 65.0956V60.9361L135.086 61.4786M147.564 65.0956H135.086M135.086 61.4786V65.0956M135.086 61.4786V60.2127L131.288 57.1383L130.203 55.1489L125.501 55.3298L117.724 70.1594L117.363 76.67L127.49 76.5434M135.086 65.0956C129.895 68.4438 128.565 71.1165 127.49 76.5434"
        stroke="#013A65"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
      <path
        d="M147.386 82.6375H114.291V84.6268H115.376L115.557 84.9885L147.386 85.1693V82.6375Z"
        stroke="#FEFAF1"
        strokeWidth="0.2"
        strokeLinecap="square"
      />
    </svg>
  );
};
