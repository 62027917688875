import * as React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

import Carusell from "./Carusell";
import InfoSteps from "./InfoSteps";
import { InfoContent } from "../types/InfoContent";
import CompanyData from "../assets/CompanyData/CompanyData";
import { AppDescriptionImage } from "../assets/images/AppDescriptionImage";
import { useTranslation } from "react-i18next";

function UnibottleHistory() {
  const { t, i18n } = useTranslation(["home"]);
  const matches = useMediaQuery("(max-width:900px)");
  const dialogScaling = useMediaQuery("(max-width:660px)");
  const smallPhone = useMediaQuery("(max-width: 480px)");
  const scaling = useMediaQuery("(max-width: 760px)");
  return (
    <Grid
      item
      container
      direction={dialogScaling ? "column" : "row"}
      xs={matches ? 12 : 10}
      mb={10}
      alignItems="center"
      justifyContent="space-between">
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent="center"
        minHeight={dialogScaling ? 200 : 250}
        style={
          dialogScaling
            ? {
                padding: 10,
                width: smallPhone ? 300 : 400,
                border: "1px solid #013A65",
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
              }
            : {
                padding: 5,
                maxWidth: scaling ? 150 : 175,
                border: "1px solid #013A65",
                borderBottomLeftRadius: "25px",
                borderTopLeftRadius: "25px",
              }
        }
        xs={3}>
        <Grid item>
          <Typography variant={"body1"} color={"primary"} textAlign="center">
            {t("historyContainers.0")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent="center"
        minHeight={dialogScaling ? 200 : 250}
        style={
          dialogScaling
            ? {
                padding: 10,
                marginTop: 20,
                width: smallPhone ? 300 : 400,
                border: "1px solid #013A65",
              }
            : {
                border: "1px solid #013A65",
                padding: 5,
                maxWidth: scaling ? 150 : 175,
              }
        }
        xs={3}>
        <Grid item>
          <Typography variant={"body1"} color={"primary"} textAlign="center">
            {t("historyContainers.1")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent="center"
        minHeight={dialogScaling ? 200 : 250}
        style={
          dialogScaling
            ? {
                padding: 10,
                marginTop: 20,
                width: smallPhone ? 300 : 400,
                border: "1px solid #013A65",
              }
            : {
                border: "1px solid #013A65",
                padding: 5,
                maxWidth: scaling ? 150 : 175,
              }
        }
        xs={3}>
        <Grid item>
          <Typography variant={"body1"} color={"primary"} textAlign="center">
            {t("historyContainers.2")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent="center"
        minHeight={dialogScaling ? 200 : 250}
        style={
          dialogScaling
            ? {
                padding: 10,
                width: smallPhone ? 300 : 400,
                marginTop: 20,
                border: "1px solid #013A65",
                borderBottomRightRadius: "25px",
                borderBottomLeftRadius: "25px",
              }
            : {
                border: "1px solid #013A65",
                padding: 5,
                maxWidth: scaling ? 150 : 175,
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px",
              }
        }
        xs={3}>
        <Grid item>
          <Typography variant={"body1"} color={"primary"} textAlign="center">
            {t("historyContainers.3")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UnibottleHistory;
