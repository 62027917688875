import * as React from "react";
import { Container, Grid, useMediaQuery } from "@mui/material";

import Carusell from "./Carusell";
import InfoSteps from "./InfoSteps";
import { InfoContent } from "../types/InfoContent";
import CompanyData from "../assets/CompanyData/CompanyData";

function CompaniesOverview() {
  const dialogScaling = useMediaQuery("(max-width:600px)");
  return (
    <Container maxWidth="md">
      <Grid
        item
        container
        direction={"row"}
        mt={4}
        mb={2}
        xs={12}
        spacing={2}
        alignItems="center"
        justifyContent="center">
        {CompanyData.map((data, index) => {
          return (
            <Grid
              key={index}
              item
              xs={3}
              minWidth={160}
              maxWidth={160}
              alignItems="center"
              justifyContent={"center"}>
              {data.logo}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default CompaniesOverview;
