import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "../../Storage/useSessionStorage";
import Button from "../Button/Button";

interface ILanguageShifter {
  flexStart?: boolean;
}

function LanguageShifter({ flexStart }: ILanguageShifter) {
  const smallPhone = useMediaQuery("(max-width: 380px)");

  const [sessionStorageData, setSessionStorageData] = useSessionStorage(
    "LANGUAGE_NORWEGIAN",
    null
  );
  const [norwegianLanguage, setNorwegianLanguage] = useState<boolean>();
  const { t, i18n } = useTranslation(["home"]);

  useEffect(() => {
    if (sessionStorageData !== null) {
      setNorwegianLanguage(sessionStorageData);
      i18n.changeLanguage(sessionStorageData ? "no" : "en"); //change the language
    } else {
      setNorwegianLanguage(true);
      setSessionStorageData(true);
      i18n.changeLanguage("no"); //change the language
    }
  }, []);

  useEffect(() => {
    if (norwegianLanguage) {
      i18n.changeLanguage("no"); //change the language
    } else {
      i18n.changeLanguage("en"); //change the language
    }
    setSessionStorageData(norwegianLanguage);
  }, [norwegianLanguage]);

  return (
    <Grid
      item
      xs={5}
      container
      justifyContent={flexStart ? "flex-start" : "flex-end"}
      alignItems="center">
      <Grid item>
        <Button
          style={{
            color: !norwegianLanguage ? "#7393AA" : "#013A65",
            padding: smallPhone ? 5 : 10,
          }}
          variant="text"
          btnTxt="no"
          onClick={() => setNorwegianLanguage(true)}
        />
      </Grid>
      <Grid item>
        <Typography color={"primary"}>/</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="text"
          btnTxt="en"
          style={{
            color: !norwegianLanguage ? "#013A65" : "#7393AA",
            padding: smallPhone ? 5 : 10,
          }}
          onClick={() => setNorwegianLanguage(false)}
        />
      </Grid>
    </Grid>
  );
}

export default LanguageShifter;
