import { Container, Grid, ThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import mainTheme from "../theme/mainTheme";

interface IDefaultWrapper {
  children: ReactNode;
  padding?: number;
  noPadding?: boolean;
  showMenu?: boolean;
  noHeader?: boolean;
}

function DefaultWrapper({
  children,
  padding,
  noPadding,
  showMenu,
  noHeader,
}: IDefaultWrapper) {
  return (
    <Container disableGutters className="container" maxWidth="md">
      <Container disableGutters maxWidth="sm">
        <ThemeProvider theme={mainTheme}>
          <Grid
            container
            paddingLeft={noPadding ? 0 : padding ? padding : 2}
            paddingRight={noPadding ? 0 : padding ? padding : 2}
            direction="column"
            alignItems={"center"}
            justifyContent="center">
            {children}
          </Grid>
        </ThemeProvider>
      </Container>
    </Container>
  );
}

export default DefaultWrapper;
