import {
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormEvent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { SendinblueEmail } from "../../types/SendinblueEmail";
import { CloseCrossIcon } from "../../assets/icons/CloseCrossIcon";

import Button from "../Button/Button";
import InputText from "../InputText/InputText";
import StatusHandler from "../StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";

interface IInterestDialog {
  children?: ReactNode;
  open: boolean;
  fullWidth?: boolean;

  onClose: () => void;
}

function InterestDialog({
  children,
  open,
  fullWidth,

  onClose,
}: IInterestDialog) {
  const [company, setCompany] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [name, setName] = useState<string>("");

  const { t } = useTranslation(["home"]);

  const sendinblueApiKey =
    "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";
  const sendinblueApiUrl = "https://api.sendinblue.com/v3/smtp/email";

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function sendEmail(email: SendinblueEmail) {
    try {
      const response = await fetch(sendinblueApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": sendinblueApiKey,
        },
        body: JSON.stringify(email),
      });
      const data = await response.json();
      console.log("Email sent:", data);
      setIsLoading(false);
      setIsSuccess(true);
      sleep(3000).then(() => {
        setIsSuccess(false);
        onClose();
      });
    } catch (error) {
      setIsError(true);
      sleep(3000).then(() => {
        setIsError(false);
      });
      console.error("Failed to send email:", error);
    }
  }

  function onSubmit(e: FormEvent) {
    const emailToUnibottle: SendinblueEmail = {
      sender: { name: "Unibottle", email: "noreply@unibottle.no" },
      to: [{ email: "kontakt@unibottle.no" }],
      subject: "Interesse for unibox/hente tjeneste - bedrift",
      htmlContent:
        "<p>En bedriftskunde har vist interesse</p> " +
        "Epost: " +
        email +
        "</br>" +
        "Telefonnummer: " +
        number +
        "</br>" +
        "Navn på bedrift: " +
        name,
    };
    const emailToUnibottleApartment: SendinblueEmail = {
      sender: { name: "Unibottle", email: "noreply@unibottle.no" },
      to: [{ email: "kontakt@unibottle.no" }],
      subject: "Interesse for unibox tjeneste - borettslag",
      htmlContent:
        "<p>En privatperson knyttet til et borettslag har vist interesse.</p> " +
        "Epost: " +
        email,
    };
    const emailToCustomer: SendinblueEmail = {
      sender: { name: "Unibottle", email: "noreply@unibottle.no" },
      to: [{ email: email }],
      subject: "Bekreftelse på forespørsel - unibottle",
      htmlContent:
        "<p>Hei, Takk for din interesse! </br> Dette er en bekreftelse på at vi har mottatt din forespørsel. Vi vil ta kontakt fortløpende.</p></br> <p>Hvis det skulle være noe mer du lurte på kan du kontakte oss på: kontakt@unibottle.no</p> </br><p>Mvh Unibottle</p>",
    };

    if (company) {
      sendEmail(emailToUnibottle);
    } else {
      sendEmail(emailToUnibottleApartment);
    }
    sendEmail(emailToCustomer);
    setIsLoading(true);
  }

  return (
    <Dialog maxWidth={"xs"} open={open} onClose={onClose}>
      <StatusHandler
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}>
        <form onSubmit={onSubmit}>
          <Grid
            container
            maxWidth={"sm"}
            alignItems="center"
            justifyContent={"space-between"}
            direction="column">
            <Grid
              item
              container
              justifyContent={"flex-end"}
              style={{ backgroundColor: "#FEFAF1" }}>
              <Grid item mr={3} mt={3}>
                <IconButton onClick={onClose}>
                  <CloseCrossIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-evenly"}
              alignItems={"flex-start"}
              pl={4}
              pr={4}
              direction={"column"}
              className={"drawerContent"}>
              <Grid item>
                <Typography variant="h6" color="primary">
                  {t("interestPage.interestForm.title")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontSize={14} color={"primary"}>
                  {t("interestPage.interestForm.underTitle")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color={"primary"}>
                  {t("interestPage.interestForm.selectorTitle")}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="company"
                    name="row-radio-buttons-group">
                    <FormControlLabel
                      value="company"
                      onClick={() => setCompany(true)}
                      style={{ color: "#013A65" }}
                      control={<Radio style={{ color: "#013A65" }} />}
                      label={t("interestPage.interestForm.options.0")}
                    />
                    <FormControlLabel
                      value="apartment"
                      onClick={() => setCompany(false)}
                      style={{ color: "#013A65" }}
                      control={<Radio style={{ color: "#013A65" }} />}
                      label={t("interestPage.interestForm.options.1")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {!company ? (
                <Grid item>
                  <InputText
                    required
                    underline
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="eksempel@mail.no"
                    label={t("interestPage.interestForm.formData.email")}
                    type="email"
                  />
                </Grid>
              ) : (
                <Grid item container direction={"column"} spacing={1}>
                  <Grid item>
                    <InputText
                      underline
                      required
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="eksempel@mail.no"
                      label={t("interestPage.interestForm.formData.email")}
                      type="email"
                    />
                  </Grid>
                  <Grid item>
                    <InputText
                      required
                      name="mobile"
                      onChange={(e) => setNumber(e.target.value)}
                      maxLength={8}
                      underline
                      label={t("interestPage.interestForm.formData.phone")}
                      type="number"></InputText>
                  </Grid>
                  <Grid item>
                    <InputText
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      underline
                      placeholder="unibottle as avdeling salg"
                      label={t("interestPage.interestForm.formData.company")}
                      type="text"></InputText>
                  </Grid>
                </Grid>
              )}
              <Grid item mt={2}>
                <Typography
                  variant="subtitle2"
                  color={"primary"}
                  textAlign="center">
                  {t("interestPage.interestForm.footerText.0")}
                  <Link style={{ color: "#013A65" }} to="/personvern">
                    {t("interestPage.interestForm.footerText.1")}
                  </Link>{" "}
                  {t("interestPage.interestForm.footerText.2")}
                  <Link style={{ color: "#013A65" }} to="/personvern">
                    {t("interestPage.interestForm.footerText.3")}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent="center"
                maxWidth={400}>
                <Grid item mt={2} width={1}>
                  <Button
                    type="submit"
                    btnTxt={t("interestPage.button")}
                    style={{
                      borderRadius: "24px",
                      fontSize: "17px",
                      maxHeight: "200px",
                      height: "48px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StatusHandler>
    </Dialog>
  );
}

export default InterestDialog;
